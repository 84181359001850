import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://kala.finance/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    initialOpenState: true,
    status: {
      text: 'MIGRATE',
      color: 'warning',
    },
    items: [
  
      {
        label: 'Exchange',
        href: 'https://exchange.kala.finance/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.kala.finance/#/pool',
      },
 
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '#',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '#',
  },

  {
    label: 'Chart',
    icon: 'InfoIcon',
    items: [
      {
        label: 'Poocoin',
        href: 'https://poocoin.app/tokens/0x5f7b680de12d3da8eab7c6309b5336ca1ef04172',
      },
      {
        label: 'Bogged Finance',
        href: 'https://charts.bogged.finance/0x5f7b680de12D3Da8eAB7C6309b5336cA1EF04172',
      },

      {
        label: 'Dextools',
        href: 'https://www.dextools.io/app/bsc/pair-explorer/0x7eb90b4e36b0e290b18e0975393ae1991cc50de3',
      },

      
   
    ],
  },
  
  
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [

      {
        label: 'BSCSCAN',
        href: 'https://bscscan.com/token/0x5f7b680de12D3Da8eAB7C6309b5336cA1EF04172',
      },
      {
        label: 'Whitepaper',
        href: 'https://drive.google.com/file/d/1O67svU0xn90UjDYy_ypttIarFxxlbUfW/view?usp=drivesdk',
      },
      {
        label: 'Liquidity Lock',
        href: '#',
      },
      {
        label: 'Audit (Soon)',
        href: '#',
      },

    ],
  },
 
  
]

export default config
